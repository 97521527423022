$(document).ready(function() {
	var slider = $('.homepage-case-studies').flickity({
	  wrapAround: true,
	  pageDots: false
	});
	$('.slider-title-controller > .next').on('click', function() {
		slider.flickity('next');
	});
	$('.slider-title-controller > .prev').on('click', function() {
		slider.flickity('previous');
	});
});